import React, {useEffect} from "react";
import shopify from "../../backend/shopify";
import Loader from "../../components/Core/Loader";

const AuthenticatePage = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const hmac = urlParams.get("hmac");
    const shop = urlParams.get("shop");

    shopify.authenticate(urlParams.toString(), shop, hmac)
      .then(({data}) => {
        const { url } = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");
        } else {
          window.location.href = url;
        }
      });
  }, []);

  return (
    <Loader/>
  );
};

export default AuthenticatePage;
